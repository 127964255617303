<template>
  <div>
    <!--面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>咨询管理</el-breadcrumb-item>
      <el-breadcrumb-item>咨询列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!--搜索与添加区 其中el-row是列空间系统 一行分为24列, 指定元素宽度添加span属性就行-->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getInformationList"
          >
            <el-button slot="append"
                       icon="el-icon-search"
                       @click="getInformationList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            @click="addDialogVisible=true"
          >
            添加咨询
          </el-button>
        </el-col>
      </el-row>

      <!--管理员列表-->
      <el-table :data="informationlist" border stripe>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="内容" prop="text"></el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <!--修改按钮-->
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
            <!--删除按钮-->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeInformationById(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.pagenum"
        :page-sizes="[2, 4, 6, 8]"
        :page-size="queryInfo.pagesize"
        layout=" sizes, prev, pager, next, jumper"
        :total="totalpage">
      </el-pagination>
    </el-card>
<!--添加咨询对话框-->
    <el-dialog
      title="添加咨询"
      :visible.sync="addDialogVisible"
      @close="addDialogClosed"
      width="45%">
    <el-form :model="addForm"
             :rules="addFormRules"
             ref="addFormRef"
             label-width="70px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="addForm.title"></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="text">
        <el-input type="textarea" v-model="addForm.text"></el-input>
      </el-form-item>
    </el-form>
    <!--底部区域-->
    <span slot="footer" class="dialog-footer">
      <el-button @click="addDialogVisible = false" >取消</el-button>
      <el-button type="primary" @click="addInformation" >确认</el-button>
  </span>
    </el-dialog>
    <!--修改咨询对话框-->
    <el-dialog
      title="修改咨询信息"
      :visible.sync="editDialogVisible"
      @close="editDialogClosed"
      width="45%">
      <el-form :model="editForm"
               :rules="editFormRules"
               ref="editFormRef"
               label-width="70px"
               class="demo-ruleForm"
               border
               status-icon
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="text">
          <el-input type="textarea" v-model="editForm.text"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">取消</el-button>
    <el-button type="primary" @click="editInformationInfo">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "Information",
    data(){
      return {
//        查询所携带的参数
        queryInfo: {
          query: "",
//        该值为在this.$http.get() 请求数据时请求多少页,和设置table有多少页
          pagenum: 1,
//        该值为在this.$http.get() 请求数据时,和设置table每页大小
          pagesize: 6
        },
        totalpage:0,
        addDialogVisible: false,
        informationlist:[],
        addForm:{
          title:"",
          text:""
        },
        addFormRules: {
//        添加表单用户名验证规则对象
          title: [
            {required: true, message: '请输入咨询标题', trigger: 'blur'},
            {min: 3, max: 15, message: '长度应该在5到20个字符', trigger: 'blur'}
          ],
          text: [
            {required: true, message: '请输入咨询内容', trigger: 'blur'},
            {min: 50,  message: '长度应该大于50', trigger: 'blur'}
          ]
        },
        editForm:{
          id:"",
          title:"",
          text:""
        },
        editFormRules: {
//        添加表单用户名验证规则对象
          title: [
            {required: true, message: '请输入咨询标题', trigger: 'blur'},
            {min: 3, max: 15, message: '长度应该在5到20个字符', trigger: 'blur'}
          ],
          text: [
            {required: true, message: '请输入咨询内容', trigger: 'blur'},
            {min: 50,  message: '长度应该大于50', trigger: 'blur'}
          ]
        },
        editDialogVisible: false,
      }
    },
    methods:{
      async getInformationList(){
        const {data:res}= await this.$http.get('information/information',{ params: this.queryInfo})
        console.log(res)
        if(res.code===200){
          this.informationlist=res.data.informationlist
          this.totalpage=res.data.totalpage
          this.queryInfo.pagenum=res.data.pagenum
        }else{
          this.$message.error("获取咨询信息失败")
        }
      },
      addInformation(){
        console.log("添加咨询")
        this.$refs.addFormRef.validate(async valid=> {
          if(!valid) return
//        发起修改用户信息的数据请求
          const {data: res}= await this.$http.post(
            'information',this.addForm)
          console.log(res)
          if(res.code!== 200) return this.$message.error(res.message)
          this.addDialogVisible= false
          this.getInformationList()
          this.$message.success('添加咨询成功')
        })
      },
      addDialogClosed(){
        this.$refs.addFormRef.resetFields()
      },
      editDialogClosed(){
        this.$refs.editFormRef.resetFields()
      },
      async showEditDialog(id){
        const  {data: res}= await this.$http.get('information/'+id)
        if(res.code!== 200){
          return this.$http.Message.error('查询咨询信息失败')
        }
        this.editDialogVisible= true
        this.editForm= res.data
      },
      async removeInformationById(id){
        const confirmResult= await this.$confirm('此操作将永久删除此咨询', '是否操作', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)

//       如果用户确认则为confirm字符串
//       如果用户取消删除,则返回值为字符串,cancl

        if(confirmResult!== 'confirm') {
          return this.$message.info('已取消删除')
        }

        const {data: res}= await this.$http.delete('information/'+id)

        if (res.code !== 200) {
          return this.$message.error('删除咨询失败')
        }

        this.$message.success('删除咨询成功')
        this.getInformationList()
      },
      //    监听pageSize改变的事件
      handleSizeChange(newSize) {
        this.queryInfo.pagesize= newSize
        this.getInformationList()
      },

//    页码值发生改变则触发此事件
      handleCurrentChange(newPage) {
        this.queryInfo.pagenum = newPage
        this.getInformationList()
      },
     async  editInformationInfo(){
        console.log(this.editForm)
        //        验证是否复合条件
        this.$refs.editFormRef.validate(async valid=> {
          if(!valid) return
//        发起修改用户信息的数据请求
          const {data: res}= await this.$http.put(
            "information",
            {
              id: this.editForm.id,
              title: this.editForm.title,
              text: this.editForm.text
            })
          if(res.code!== 200) return this.$message.error("更新咨询信息失败")
          this.editDialogVisible= false
          this.getInformationList()
          this.$message.success('更新咨询信息成功')
        })
      }
    },
    created(){
      this.getInformationList()
    }

  }
</script>

<style lang='less' scoped>

</style>
