<template>
  <div>
    <el-card>
      <el-row><img src="../assets/img/kingRanking1.png" alt="" style="float: left;width: 20%;"></el-row>
      <el-row><img src="../assets/img/kingRanking2.png" alt="" style="float: right;width: 20%;"></el-row>
      <el-row><img src="../assets/img/kingRanking3.png" alt="" style="float: left;width: 20%;"></el-row>
    </el-card>
  </div>
</template>

<script>
  export default {
    name: "Welcome"
  }
</script>

<style lang='less' scoped>

</style>
