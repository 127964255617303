<template>
  <div>
    <!--面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!--搜索与添加区 其中el-row是列空间系统 一行分为24列, 指定元素宽度添加span属性就行-->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getUserList"
          >
            <el-button slot="append"
                       icon="el-icon-search"
                       @click="getUserList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table :data="userList" border stripe>
        <el-table-column type="index" ></el-table-column>
        <el-table-column label="id" prop="openid"></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <el-table-column label="电话" prop="phone"></el-table-column>
        <el-table-column label="注册时间" prop="created"></el-table-column>
        <el-table-column label="更改时间" prop="updated"></el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.openid)"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini"
                       @click="removeByUserId(scope.row.openid)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.pagenum"
        :page-sizes="[2, 4, 6, 8]"
        :page-size="queryInfo.pagesize"
        layout=" sizes, prev, pager, next, jumper"
        :total="totalpage">
      </el-pagination>
    </el-card>
    <!--修改用户对话框-->
    <el-dialog
      title="修改用户信息"
      :visible.sync="editDialogVisible"
      @close="editDialogClosed"
      width="45%">
      <el-form :model="editForm"
               :rules="editFormRules"
               ref="editFormRef"
               label-width="70px"
               class="demo-ruleForm"
               border
               status-icon
      >
        <el-form-item label="用户名" >
          <el-input type="text"  v-model="editForm.openid" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="email" prop="email">
          <el-input type="text"  v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input type="text"  v-model="editForm.phone"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">取消</el-button>
    <el-button type="primary" @click="editUserInfo">确定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  export default {
    name: "User",
    data(){
      //        验证邮箱的规则
      let checkEmail= (rule,value,cb)=> {
//          用正则表达式验证邮箱格式是否有效
        const regmail=/^[A-Za-z\d]+[A-Za-z\d\-_\.]*@([A-Za-z\d]+[A-Za-z\d\-]*\.)+[A-Za-z]{2,4}$/
        if(regmail.test(value)) return cb()

        cb(new Error('请输入合法的邮箱'))
      }
//        验证手机号规则
      let checkMobile= (rule,value,cb)=> {
        console.log(value)
        const regMobile=/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if(regMobile.test(value)) return cb()
        cb(new Error('请输入合法的手机号'))
      }
      return {
//        查询所携带的参数
        queryInfo: {
          query: "",
//        该值为在this.$http.get() 请求数据时请求多少页,和设置table有多少页
          pagenum: 1,
//        该值为在this.$http.get() 请求数据时,和设置table每页大小
          pagesize: 6,
        },
//        获得的用户列表
        userList: [],
//        获取用户信息的总页数
        totalpage:0,

//        编辑用户信息的对话框是否可见
        editDialogVisible:false,
        editForm:{
          openid:"",
          email:"",
          phone:""
        },
//        修改用户对话框表单校验规则
        editFormRules: {
          email: [
            { message: '请输入您的邮箱', trigger: 'blur'},
            {validator: checkEmail, trigger: 'blur'}
          ],
          mobile: [
            {message: '请输入您的手机号', trigger: 'blur'},
            {validator: checkMobile, trigger: 'blur'}
          ]
        }

      }
    },
    methods:{
//      获取用户列表
      async getUserList(){
        const {data:res}= await this.$http.get('user/userlist',{ params: this.queryInfo})
        if(res.code===200){
          this.userList=res.data.users
          this.totalpage=res.data.totalpage
          this.queryInfo.pagenum=res.data.pagenum
        }else{
          this.$message.error("获取用户信息失败")
        }
      },

//     编辑用户信息按钮的点击事件
      async showEditDialog(openid){
        console.log(openid)
        const  {data: res}= await this.$http.get('user/'+openid)
        if(res.code!== 200){
          return this.$http.Message.error('查询用户信息失败')
        }
        this.editDialogVisible= true
        this.editForm= res.data
      },
//      编辑对话框关闭
      editDialogClosed(){
        this.$refs.editFormRef.resetFields()
      },
//      确定修改用户信息
      editUserInfo(){
        this.$refs.editFormRef.validate(async valid=> {
          if(!valid) return
//        发起修改用户信息的数据请求
          const {data: res}= await this.$http.put(
            'user/update/'+this.editForm.openid,
            {
              email: this.editForm.email,
              phone: this.editForm.phone
            })
          if(res.code!== 200) return this.$Message.error("更新用户信息失败")
          this.editDialogVisible= false
          this.getUserList()
          this.$message.success('更新用户信息成功')
        })
      },

//      通过userid删除用户
      async removeByUserId(id){
        const confirmResult= await this.$confirm('此操作将永久删除此用户', '是否操作', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)

//       如果用户确认则为confirm字符串
//       如果用户取消删除,则返回值为字符串,cancl

        if(confirmResult!== 'confirm') {
          return this.$message.info('已取消删除')
        }

        const {data: res}= await this.$http.delete('user/'+id)

        if (res.code !== 200) {
          return this.$message.error('删除用户失败')
        }

        this.$message.success('删除用户成功')
        this.getUserList()
      },

      //    监听pageSize改变的事件
      handleSizeChange(newSize) {
        this.queryInfo.pagesize= newSize
        this.getUserList()
      },

//    页码值发生改变则触发此事件
      handleCurrentChange(newPage) {
        this.queryInfo.pagenum = newPage
        this.getUserList()
      },

    },
    created(){
      this.getUserList()
      console.log("获取信息成功")
    }
  }
</script>

<style lang='less' scoped>

</style>
