<template>
  <div>
    <!--面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>社区管理</el-breadcrumb-item>
      <el-breadcrumb-item>社区列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!--搜索与添加区 其中el-row是列空间系统 一行分为24列, 指定元素宽度添加span属性就行-->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getCommunityList"
          >
            <el-button slot="append"
                       icon="el-icon-search"
                       @click="getCommunityList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            @click="addDialogVisible=true"
          >
            添加小区
          </el-button>
        </el-col>
      </el-row>
      <el-table :data="communitylist" border stripe>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="所在省" prop="province"></el-table-column>
        <el-table-column label="所在市" prop="city"></el-table-column>
        <el-table-column label="所在区/县" prop="area"></el-table-column>
        <el-table-column label="详细地址" prop="address"></el-table-column>
        <el-table-column label="经度" prop="longitude"></el-table-column>
        <el-table-column label="纬度" prop="latitude"></el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini"
                       @click="removeByCommunityId(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.pagenum"
        :page-sizes="[2, 4, 6, 8]"
        :page-size="queryInfo.pagesize"
        layout=" sizes, prev, pager, next, jumper"
        :total="totalpage">
      </el-pagination>
    </el-card>
    <!--修改用户对话框-->
    <el-dialog
      title="修改小区信息"
      :visible.sync="editDialogVisible"
      @close="editDialogClosed"
      width="45%">
      <el-form :model="editForm"
               :rules="editFormRules"
               ref="editFormRef"
               label-width="100px"
               class="demo-ruleForm"
               border
               status-icon
      >
        <el-form-item label="小区名称" >
          <el-input type="text"  v-model="editForm.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input type="text"  v-model="editForm.address"></el-input>
        </el-form-item>
        <el-form-item label="经度" prop="longitude">
          <el-input type="text"  v-model="editForm.longitude"></el-input>
        </el-form-item>
        <el-form-item label="纬度" prop="latitude">
          <el-input type="text"  v-model="editForm.latitude"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">取消</el-button>
    <el-button type="primary" @click="editCommunityInfo">确定</el-button>
      </span>
    </el-dialog>
    <!--添加用户对话框-->    <!--visible是否可见,绑定在一个boolean上-->
    <el-dialog
      title="添加管理员"
      :visible.sync="addDialogVisible"
      width="45%"
      @close="addDialogClosed"
    >
      <!--内容主体区域-->
      <el-form :model="addForm"
               :rules="addFormRules"
               ref="addFormRef"
               label-width="100px">
        <el-form-item label="小区名称" prop="name">
        <el-input v-model="addForm.name"></el-input>
       </el-form-item>
        <el-form-item label="区/县" prop="area">
          <el-cascader
            v-model="area"
            :options="areaOptions"
            :props="cascadeProps"
            @change="handleCascaderChange"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="addForm.address"></el-input>
        </el-form-item>
        <el-form-item label="经度" prop="longitude">
          <el-input v-model="addForm.longitude"></el-input>
        </el-form-item>
        <el-form-item label="纬度" prop="latitude">
          <el-input v-model="addForm.latitude"></el-input>
        </el-form-item>
        <el-form-item label="建造年代" prop="year">
          <el-input v-model="addForm.year" type="number"></el-input>
        </el-form-item>
      </el-form>
      <!--底部区域-->
      <span slot="footer" class="dialog-footer">
      <el-button @click="addDialogVisible = false" >取消</el-button>
      <el-button type="primary" @click="addCommunity()" >确认</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "Community",
    data(){
      //        验证经纬度规则
      let checkLongitude= (rule,value,cb)=> {
//          用正则表达式验证邮箱格式是否有效
        const regmail=/^[\-\+]?(0?\d{1,2}|0?\d{1,2}\.\d{1,15}|1[0-7]?\d{1}|1[0-7]?\d{1}\.\d{1,15}|180|180\.0{1,15})$/
        if(regmail.test(value)) return cb()

        cb(new Error('请输入合法的经度'))
      }
      let checkLatitude= (rule,value,cb)=> {
//          用正则表达式验证邮箱格式是否有效
        const regmail=/^[\-\+]?([0-8]?\d{1}|[0-8]?\d{1}\.\d{1,15}|90|90\.0{1,15})$/
        if(regmail.test(value)) return cb()

        cb(new Error('请输入合法的纬度'))
      }
      let checkYear= (rule,value,cb)=> {
//          用正则表达式验证邮箱格式是否有效
        const regmail=/[0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3}/
        if(regmail.test(value)) return cb()

        cb(new Error('请输入合法的年数'))
      }
      return{
//        查询所携带的参数
        queryInfo: {
          query: "",
//        该值为在this.$http.get() 请求数据时请求多少页,和设置table有多少页
          pagenum: 1,
//        该值为在this.$http.get() 请求数据时,和设置table每页大小
          pagesize: 6,
        },
//        获得的用户列表
        communitylist: [],
//        获取用户信息的总页数
        totalpage:0,
        editDialogVisible:false,
        editForm:{},
        editFormRules:{
          address: [
            { required: true, message: '请输入详细地址', trigger: 'blur'},
            {min: 5,  message: '长度应该大于5个字符', trigger: 'blur'}
          ],
          longitude: [
            { required: true, message: '请输入小区经度', trigger: 'blur'},
            {validator: checkLongitude, trigger: 'blur'}
          ],
          latitude: [
            { required: true, message: '请输入小区纬度', trigger: 'blur'},
            {validator: checkLatitude, trigger: 'blur'}
          ]
        },
        addDialogVisible: false,
        addForm:{
          name:"",
          province:"",
          city:"",
          area:"",
          address:"",
          latitude:"",
          longitude:"",
          year:""
        },
        addFormRules:{
          name: [
            { required: true, message: '请输入小区名称', trigger: 'blur'},
            {min: 3,  message: '长度应该大于3个字符', trigger: 'blur'}
          ],
          longitude: [
            { required: true, message: '请输入小区经度', trigger: 'blur'},
            {validator: checkLongitude, trigger: 'blur'}
          ],
          latitude: [
            { required: true, message: '请输入小区纬度', trigger: 'blur'},
            {validator: checkLatitude, trigger: 'blur'}
          ],
          address: [
            { required: true, message: '请输入详细地址', trigger: 'blur'},
            {min: 5,  message: '长度应该大于5个字符', trigger: 'blur'}
          ],
          year: [
            { required: true, message: '请输入年数', trigger: 'blur'},
            {validator: checkYear, trigger: 'blur'}
          ],
          area:[
            { required: true, message: '请选择区/县', trigger: 'change'},
          ]
        },
        areaOptions:[],
        cascadeProps:{
          expandTrigger:'hover',
          label:'label',
          value:'value',
          children:'children'
        },
        area:[]

      }
    },
    methods:{
      async getCommunityList(){
        const {data:res}= await this.$http.get('community/community',{ params: this.queryInfo})
        console.log(res)
        if(res.code===200){
          this.communitylist=res.data.communitylist
          this.totalpage=res.data.totalpage
          this.queryInfo.pagenum=res.data.pagenum
        }else{
          this.$message.error("获取用户信息失败")
        }
      },
      async showEditDialog(id){
        console.log(id)
        const  {data: res}= await this.$http.get('community/'+id)
        if(res.code!== 200){
          return this.$http.Message.error('查询社区信息失败')
        }
        this.editForm= res.data
        this.editDialogVisible=true
      },
      async removeByCommunityId(id){
        const confirmResult= await this.$confirm('此操作将永久删除该社区信息', '是否操作', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)

//       如果用户确认则为confirm字符串
//       如果用户取消删除,则返回值为字符串,cancl

        if(confirmResult!== 'confirm') {
          return this.$message.info('已取消删除')
        }

        const {data: res}= await this.$http.delete('community/'+id)

        if (res.code !== 200) {
          return this.$message.error('删除小区失败')
        }

        this.$message.success('删除小区成功')
        this.getCommunityList()
      },
      //    监听pageSize改变的事件
      handleSizeChange(newSize) {
        this.queryInfo.pagesize= newSize
        this.getCommunityList()
      },

//    页码值发生改变则触发此事件
      handleCurrentChange(newPage) {
        this.queryInfo.pagenum = newPage
        this.getCommunityList()
      },
//      编辑对话框关闭事件
      editDialogClosed(){
      this.$refs.editFormRef.resetFields()
    },
//      确定修改小区信息
      editCommunityInfo(){
        this.$refs.editFormRef.validate(async valid=> {
          if(!valid) return
//        发起修改小区信息的请求
          const {data: res}= await this.$http.put(
            'community/update/'+this.editForm.id,
            {
              address: this.editForm.address,
              longitude: this.editForm.longitude,
              latitude: this.editForm.latitude
            })
          if(res.code!== 200) return this.$Message.error("更新小区信息失败")
          this.editDialogVisible= false
          this.getCommunityList()
          this.$message.success('更新小区信息成功')
        })
      },
      addDialogClosed(){
        this.$refs.addFormRef.resetFields()
      },
      addCommunity(){
        this.$refs.addFormRef.validate(async valid=> {
          if(!valid) return
//        发起修改用户信息的数据请求
          const {data: res}= await this.$http.post(
            "community/", this.addForm)
          if(res.code!== 200) return this.$message.error("添加小区失败")
          this.addDialogVisible= false
          this.getCommunityList()
          this.$message.success('添加小区成功')
        })
      },
      handleCascaderChange(res){
        this.addForm.province=res[0]
        this.addForm.city=res[1]
        this.addForm.area=res[2]
        console.log(this.area)
      },
      async getAreaList(){
        const {data:res} = await this.$http.get("/region/regionlist")
        if(res.code!==200) return this.$message.error("获取区县列表失败")
        this.areaOptions=res.data
        console.log(res.data)
      }
    },
    created(){
      this.getCommunityList()
      this.getAreaList()
      }

  }
</script>

<style lang='less' scoped>

</style>
