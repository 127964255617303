<template>
  <el-container class="home-container">
    <!--头部区域-->
    <el-header>
      <div>
        <img src="../assets/logo.png" alt="">
        <span>易租赁后台管理系统</span>
      </div>
      <el-button @click="logOut" type="info">退出</el-button>
    </el-header>
    <!--el整体区域-->
    <el-container>
      <!--侧边栏-->
      <el-aside :width="isAsideColl ? '64px' : '200px'">
        <div class="toggle-button" @click="turnAsideColl">|||</div>
        <!--侧边栏菜单区-->
        <el-menu
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409EFF"
          :uniqueOpened="true"
          :collapse="isAsideColl"
          :collapse-transition= "false"
          :router= 'true'
          :defaultActive="activePath"
        >
          <!--一级菜单区-->
          <!--index只接受字符串不接受数值-->
          <el-submenu :index="item.id+ ''" v-for="item in menuList" :key="item.id">
            <!--以及菜单模板区-->
            <template slot="title">
              <!--图标-->
              <i :class="iconObj[item.id]" ></i>
              <!--文本-->
              <span>{{item.authName}}</span>
            </template>
            <!--二级菜单,因为是最后一级菜单,里面不在有下一级菜单所以不再用el-submenu包裹-->
            <el-menu-item
              :index="'/'+subItem.path"
              v-for="subItem in item.children"
              :key="subItem.id"
              @click="saveNavState('/'+subItem.path)"
            >
              <template slot="title">
                <!--图标-->
                <i class="el-icon-menu"></i>
                <!--文本-->
                <span>{{subItem.authName}}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!--右侧内容区域-->
      <el-main>
        <!--路由占位符-->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  export default {
    name: "Home",
    data() {
      return {
        menuList: [
          {
            "id": 125,
            "authName": "用户管理",
            "children": [
              {
                "id": 1251,
                "authName": "用户列表",
                "path": "user",
              },{
                "id": 1252,
                "authName": "管理员列表",
                "path": "admin",
              }
            ]
          },{
            "id": 101,
            "authName": "房源管理",
            "children": [
              {
                "id": 1011,
                "authName": "房源列表",
                "path": "house",
              }
            ]
          },{
            "id": 103,
            "authName": "收藏管理",
            "children": [
              {
                "id": 1031,
                "authName": "收藏列表",
                "path": "collection",
              }
            ]
          },{
            "id": 102,
            "authName": "社区管理",
            "children": [
              {
                "id": 1021,
                "authName": "社区列表",
                "path": "community",
              }
            ]
          },{
            "id": 145,
            "authName": "咨询管理",
            "children": [
              {
                "id": 1451,
                "authName": "咨询列表",
                "path": "information",
              }
            ]
          }
        ],
//        一级侧边栏字体图标名称数组,根据id值进行获取
        iconObj: {
          '125': 'iconfont icon-users',
          '103': 'iconfont icon-tijikongjian',
          '101': 'iconfont icon-shangpin',
          '102': 'iconfont icon-danju',
          '145': 'iconfont icon-baobiao'
        },
        isAsideColl: false,
//        被激活的动态地址
        activePath: '',
      }
    },
    created() {
      console.log('已输出')
      this.activePath= window.sessionStorage.getItem('activePath')
    },
    methods: {
      logOut() {
        window.sessionStorage.clear()
        this.$router.push('/login')
      },
//
      turnAsideColl() {
        this.isAsideColl= !this.isAsideColl
      },
      saveNavState(path) {
        window.sessionStorage.setItem('activePath',path)
        this.activePath= window.sessionStorage.getItem('activePath')
      }
    }
  }
</script>

<style lang='less' scoped>
  .el-header {
    background-color: #373D41;
    padding-left: 0;
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 20px;
    img{
      width:50px;
      border-radius:25px;
    }
    div {
      display: flex;
      align-items: center;
      span {
        margin-left: 15px;
      }
    }

  }
  .el-aside {
    background-color: #333744;
    .el-menu {
      border-right: none;
      .el-submenu i {
        margin-right: 10px;
      }
    }

  }
  .el-main {
    background-color: #eaedf1;
  }
  .home-container {
    height: 100%;
  }
  .toggle-button {
    display: block;
    background-color: #4A5064;
    font-size: 10px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.2em;
    cursor: pointer;
  }
</style>
