<template>
  <div>
    <!--面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>房源列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片视图,主内容区-->
    <el-card>
      <!--搜索与添加区 其中el-row是列空间系统 一行分为24列, 指定元素宽度添加span属性就行-->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getHouseList"
          >
            <el-button slot="append"
                       icon="el-icon-search"
                       @click="getHouseList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!--房源列表-->
      <el-table :data="houselist" border stripe>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="小区" prop="communityName"></el-table-column>
        <el-table-column label="所在区县" prop="areaName"></el-table-column>
        <el-table-column label="电话" prop="phone"></el-table-column>
        <el-table-column label="创建时间" prop="created"></el-table-column>
        <el-table-column label="更改时间" prop="updated"></el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <!--<el-button type="primary" icon="el-icon-edit" size="mini" @click="navigeterToEditHouse(scope.row.id)"></el-button>-->
            <el-button type="danger" icon="el-icon-delete" size="mini"
                       @click="removeByHouseId(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.pagenum"
        :page-sizes="[2, 4, 6, 8]"
        :page-size="queryInfo.pagesize"
        layout=" sizes, prev, pager, next, jumper"
        :total="totalpage">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
  export default {
    name: "House",
    data(){
      return{
//        查询所携带的参数
        queryInfo: {
          query: "",
//        该值为在this.$http.get() 请求数据时请求多少页,和设置table有多少页
          pagenum: 1,
//        该值为在this.$http.get() 请求数据时,和设置table每页大小
          pagesize: 6,
        },
        totalpage:0,
        houselist:[]
      }
    },
    methods:{
      async getHouseList(){
        console.log("获取信息")
        const {data:res}= await this.$http.get('house/houselist',{ params: this.queryInfo})
        console.log(res)
        if(res.code===200){
          this.houselist=res.data.houselist
          this.totalpage=res.data.totalpage
          this.queryInfo.pagenum=res.data.pagenum
        }else{
          this.$message.error("获取用户信息失败")
        }
      },
      //    监听pageSize改变的事件
      handleSizeChange(newSize) {
        this.queryInfo.pagesize= newSize
        this.getHouseList()
      },

//    页码值发生改变则触发此事件
      handleCurrentChange(newPage) {
        this.queryInfo.pagenum = newPage
        this.getHouseList()
      },
      async removeByHouseId(id){
        const confirmResult= await this.$confirm('此操作将永久删除该房源', '是否操作', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)

//       如果用户确认则为confirm字符串
//       如果用户取消删除,则返回值为字符串,cancl

        if(confirmResult!== 'confirm') {
          return this.$message.info('已取消删除')
        }

        const {data: res}= await this.$http.delete('house/'+id)

        if (res.code !== 200) {
          return this.$message.error('删除用户失败')
        }

        this.$message.success('删除用户成功')
        this.getHouseList()
      },
      navigeterToEditHouse(id){
        this.$router.push({
          path:"/house/edit",
          query:{
            id: id
          }
        })
      }
    },
    created(){
      this.getHouseList()
    }
  }
</script>

<style lang='less' scoped>
</style>
