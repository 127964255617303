import Vue from 'vue'
import VueRouter from 'vue-router'
import login from "../components/Login.vue"
import Home from "../components/Home.vue"
import User from "../components/user/User.vue"
import Admin from "../components/user/Admin.vue"
import House from "../components/house/House.vue"
import EditHouse from "../components/house/EditHouse.vue"
import Collection from "../components/collection/Collection.vue"
import Community from "../components/community/Community.vue"
import Welcome from "../components/Welcome.vue"
import Information from "../components/information/Information.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login'
  },
  {
    path: '/login',
    component:login
  },
  {
    path: "/home",
    component: Home,
    redirect: 'welcome',
    children: [
      {
        path: '/user',
        component: User
      },
      {
        path: '/admin',
        component: Admin
      },
      {
        path: "/house",
        component: House
      },
      {
        path: "/house/edit",
        component: EditHouse
      },
      {
        path: "/collection",
        component: Collection
      },
      {
        path: "/community",
        component: Community
      },
      {
        path:"/welcome",
        component: Welcome
      },
      {
        path:"/information",
        component: Information
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
