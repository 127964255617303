import Vue from 'vue'
import { Button,Form, FormItem,Input,Message,Notification,Container,Header,
  Aside,Main,Menu,Submenu,MenuItemGroup,MenuItem,Breadcrumb,BreadcrumbItem,
  Card,Row,Col, Table,TableColumn,Switch,Tooltip,Pagination,Dialog,MessageBox,
  Tag,Tree,Select,Option,Cascader,Alert,Tabs,TabPane,Steps,
  Step,CheckboxGroup,Checkbox,Upload } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Select)
Vue.use(Option)
Vue.use(Cascader)
Vue.use(Alert)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Steps)
Vue.use(Step)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Upload)

//message和上面的不一样,message需要全局挂载,$Message是自定义名称没有强制要求
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification
Vue.prototype.$confirm= MessageBox.confirm
