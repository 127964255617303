import Vue from 'vue'
import App from './App.vue'

//引入路由
import router from './router'


//引入element
import './plugins/element.js'

//引入axios
import axios from 'axios'
// axios.defaults.baseURL = 'https://localhost:8080/api/backend/service/'
axios.defaults.baseURL = 'https://www.tudoupro.com:8080/api/backend/service/'
//配置拦截器,每次请求都将sessionStorage的token放到请求头
axios.interceptors.request.use(config=>{
  //给请求头配置一个名为Authorization的字段 token
  config.headers.Authorization= window.sessionStorage.getItem('token')
  return config
})
Vue.prototype.$http = axios

//引入字体图标
import './assets/fonts/iconfont.css'

//导入全局样式表
import './assets/css/global.css'

//配置全局过滤器
Vue.filter('dateFormat', function (originVal) {
  const dt=new Date(originVal*1000)
  const y=dt.getFullYear()
  // padStart 如果不足两位在前面追加0
  const m=(dt.getMonth()+1+'').padStart(2,'0')
  const d=(dt.getDate()+'').padStart(2,'0')
  const hh=(dt.getHours()+'').padStart(2,'0')
  const mm=(dt.getMinutes()+'').padStart(2,'0')
  const ss=(dt.getSeconds()+'').padStart(2,'0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
