<template>
  <div>
    <!--面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>管理员列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片视图-->
    <el-card>
      <!--搜索与添加区 其中el-row是列空间系统 一行分为24列, 指定元素宽度添加span属性就行-->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getAdminList"
          >
            <el-button slot="append"
                       icon="el-icon-search"
                       @click="getAdminList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            @click="addDialogVisible=true"
          >
            添加用户
          </el-button>
        </el-col>
      </el-row>
      <!--管理员列表-->
      <el-table :data="adminlist" border stripe>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="用户名" prop="username"></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <el-table-column label="电话" prop="phone"></el-table-column>
        <el-table-column label="注册时间" prop="created"></el-table-column>
        <el-table-column label="更改时间" prop="updated"></el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <!--修改按钮-->
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
            <!--删除按钮-->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeAdminById(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.pagenum"
        :page-sizes="[2, 4, 6, 8]"
        :page-size="queryInfo.pagesize"
        layout=" sizes, prev, pager, next, jumper"
        :total="totalpage">
      </el-pagination>
    </el-card>
    <!--添加用户对话框-->    <!--visible是否可见,绑定在一个boolean上-->
    <el-dialog
      title="添加管理员"
      :visible.sync="addDialogVisible"
      width="45%"
      @close="addDialogClosed"
    >
      <!--内容主体区域-->
      <el-form :model="addForm"
               :rules="addFormRules"
               ref="addFormRef"
               label-width="70px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
      </el-form>
      <!--底部区域-->
      <span slot="footer" class="dialog-footer">
      <el-button @click="addDialogVisible = false" >取消</el-button>
      <el-button type="primary" @click="addAdmin" >确认</el-button>
  </span>
    </el-dialog>
    <!--修改用户对话框-->
    <el-dialog
      title="修改管理员信息"
      :visible.sync="editDialogVisible"
      @close="editDialogClosed"
      width="45%">
      <el-form :model="editForm"
               :rules="editFormRules"
               ref="editFormRef"
               label-width="70px"
               class="demo-ruleForm"
               border
               status-icon
      >
        <el-form-item label="用户名" >
          <el-input type="text"  v-model="editForm.username" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="email" prop="email">
          <el-input type="text"  v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input type="text"  v-model="editForm.phone"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">取消</el-button>
    <el-button type="primary" @click="editAdminInfo">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "Admin",
    data(){
      let checkEmail= (rule,value,cb)=> {
//          用正则表达式验证邮箱格式是否有效
        const regmail=/^[A-Za-z\d]+[A-Za-z\d\-_\.]*@([A-Za-z\d]+[A-Za-z\d\-]*\.)+[A-Za-z]{2,4}$/
        if(regmail.test(value)) return cb()

        cb(new Error('请输入合法的邮箱'))
      }
//        验证手机号规则
      let checkMobile= (rule,value,cb)=> {
        console.log(value)
        const regMobile=/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if(regMobile.test(value)) return cb()
        cb(new Error('请输入合法的手机号'))
      }
      return{
//        查询所携带的参数
        queryInfo: {
          query: "",
//        该值为在this.$http.get() 请求数据时请求多少页,和设置table有多少页
          pagenum: 1,
//        该值为在this.$http.get() 请求数据时,和设置table每页大小
          pagesize: 6,
        },
        adminlist:[],
        totalpage:0,
        addDialogVisible:false,
        addForm:{
          username:"",
          password:"",
          email:"",
          phone:""
        },
          addFormRules: {
//        添加表单用户名验证规则对象
            username: [
              {required: true, message: '请输入您的用户名', trigger: 'blur'},
              {min: 3, max: 15, message: '长度应该在3到10个字符', trigger: 'blur'}
            ],
            password: [
              {required: true, message: '请输入您的密码', trigger: 'blur'},
              {min: 6, max: 15, message: '长度应该在6到15个字符', trigger: 'blur'}
            ],
            email: [
              {required: true, message: '请输入您的邮箱', trigger: 'blur'},
              {validator: checkEmail, trigger: 'blur'}
            ],
            phone: [
              {required: true, message: '请输入您的手机号', trigger: 'blur'},
              {validator: checkMobile, trigger: 'blur'}

            ],
        },
        editForm:{},
        editDialogVisible:false,
        editFormRules:{
          password: [
            {required: true, message: '请输入您的密码', trigger: 'blur'},
            {min: 6, max: 15, message: '长度应该在6到15个字符', trigger: 'blur'}
          ],
          email: [
            {required: true, message: '请输入您的邮箱', trigger: 'blur'},
            {validator: checkEmail, trigger: 'blur'}
          ],
          phone: [
            {required: true, message: '请输入您的手机号', trigger: 'blur'},
            {validator: checkMobile, trigger: 'blur'}

          ]
        }
      }
    },
    methods:{
      async getAdminList(){
        const {data: res}= await this.$http.get("/admin/admin",{
          params: this.queryInfo
        })
        console.log(res)
        if(res.code!==200){
//          说明数据获取失败
         return this.$message.error("获取管理员信息失败")
        }

        this.adminlist= res.data.adminlist
        this.queryInfo.pagenum=res.data.pagenum
        this.totalpage=res.data.totalpage
      },
//      显示编辑对话框
      async showEditDialog(id){
        console.log("显示编辑对话框")
        const  {data: res}= await this.$http.get('admin/'+id)
        if(res.code!== 200){
          return this.$http.Message.error('查询用户信息失败')
        }
        this.editDialogVisible= true
        this.editForm= res.data

      },
//      监听编辑对话框关闭事件
      editDialogClosed(){
        this.$refs.editFormRef.resetFields()
      },
//      通过adminid删除此管理员
      async removeAdminById(id){
        const confirmResult= await this.$confirm('此操作将永久删除此管理员', '是否操作', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)

//       如果用户确认则为confirm字符串
//       如果用户取消删除,则返回值为字符串,cancl

        if(confirmResult!== 'confirm') {
          return this.$message.info('已取消删除')
        }

        const {data: res}= await this.$http.delete('admin/'+id)

        if (res.code !== 200) {
          return this.$message.error('删除管理员失败')
        }

        this.$message.success('删除管理员成功')
        this.getAdminList()
      },
      //    监听pageSize改变的事件
      handleSizeChange(newSize) {
        this.queryInfo.pagesize= newSize
        this.getAdminList()
      },

//    页码值发生改变则触发此事件
      handleCurrentChange(newPage) {
        this.queryInfo.pagenum = newPage
        this.getAdminList()
      },
//      监听对话框关闭事件
      addDialogClosed(){
        this.$refs.addFormRef.resetFields()
      },
//      添加管理员对话框
      addAdminDialog(){
        this.addDialogVisible=true
      },
//      添加管理员
      addAdmin(){
        console.log("添加管理员")
        this.$refs.addFormRef.validate(async valid=> {
          if(!valid) return
//        发起修改用户信息的数据请求
          const {data: res}= await this.$http.post(
            'admin/addadmin',this.addForm)
          console.log(res)
          if(res.code!== 200) return this.$message.error(res.message)
          this.addDialogVisible= false
          this.getAdminList()
          this.$message.success('添加管理员成功')
        })
      },
//      编辑管理员信息
      editAdminInfo(){
//        验证是否复合条件
        this.$refs.editFormRef.validate(async valid=> {
          if(!valid) return
//        发起修改用户信息的数据请求
          const {data: res}= await this.$http.put(
            "admin/update/"+this.editForm.id,
            {
              username: this.editForm.username,
              email: this.editForm.email,
              phone: this.editForm.phone
            })
          if(res.code!== 200) return this.$message.error("更新管理员信息失败")
          this.editDialogVisible= false
          this.getAdminList()
          this.$message.success('更新管理员信息成功')
        })
      }

    }
    ,
    created(){
      this.getAdminList()
    }

  }
</script>

<style lang='less' scoped>

</style>
