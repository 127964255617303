<template>
  <div class="login_container">
    <div class="login_box">
      <!--头像区-->
      <div class="avatar_box">
        <img src="../assets/logo.png" alt="">
      </div>
      <!--表单区-->
      <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef" label-width="0px" class="login_form">
        <!--用户名-->
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" prefix-icon="icon-user iconfont" placeholder="用户名"></el-input>
        </el-form-item>
        <!--密码-->
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" type="password" prefix-icon="icon-3702mima iconfont" placeholder="密码"></el-input>
        </el-form-item>
        <!--按钮区域-->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登陆</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Login",
    data(){
      return{
        loginForm:{
          username:'',
          password:''
        },
        loginFormRules: {
        username: [
          {required: true, message: '请输入您的用户名', trigger: 'blur'},
          {min: 3, max: 10, message: '长度应该在3到10个字符', trigger: 'blur'}
        ],
          password: [
          {required: true, message: '请输入您的密码', trigger: 'blur'},
          {min: 6, max: 15, message: '长度应该在6到15个字符', trigger: 'blur'}
        ]
      }

      }
    },
    methods:{
      login(){
//        调用表单的validate方法判断是否复合输入条件
        this.$refs.loginFormRef.validate(async  valid =>{
//        如果不符合登陆条件,则弹出提示
          if(!valid){
             return this.loginValidFalse()
          }
//          其中await只能用在被async修饰的方法中
          const {data: res} = await this.$http.post('login', this.loginForm)
          if (res.code !== 200) {
            return this.loginFalse(res.message)
          }
          this.loginSuccess()
          window.sessionStorage.setItem('token', res.data)
          this.$router.push("/home")

        })
      },
      resetLoginForm() {

      },
      loginValidFalse(){
        this.$message({
          message: "输入格式有误,请重新输入",
          type: 'error'
        })
      },
      loginFalse(msg){
        this.$message({
          message: msg,
          type: 'error'
        })
      },
      loginSuccess(){
        this.$message({
          message: "登陆成功",
          type: "success"
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .login_container{
    background-color: #83c9b6;
    height:100%;
    .login_box{
      width: 450px;
      height:300px;
      background-color: #FFf;
      border-radius: 3px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      .avatar_box {
        height: 130px;
        width: 130px;
        border: 1px solid #eee;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 0 10px #ddd;
        position: absolute;
        left: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #eee;
        }
      }
      .login_form {
        position: absolute;
        bottom: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px
      }

      .btns {
        display: flex;
        justify-content: flex-end;
      }

    }
  }
</style>
