<template>
  <div>
    <!--面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>房源管理</el-breadcrumb-item>
      <el-breadcrumb-item>修改房源</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-alert
        title="修改房源信息"
        type="info"
        center
        :closable="false"
        show-icon>
      </el-alert>
      <el-form
        :model="house"
        :rules="editRules"
        ref="addFormRef"
        label-width="120px"
        >
        <el-form-item label="房源id"   prop="id" >
          <el-input v-model="house.id" type="string" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="用户id" prop="userId">
          <el-input v-model="house.userId" type="string" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="城市" prop="cityName">
          <el-input v-model="house.cityName" type="string" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="地区" prop="areaName">
          <el-input v-model="house.areaName" type="string" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="小区名称" prop="communityName">
          <el-input v-model="house.communityName" type="string" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="楼层" prop="floor">
          <el-input v-model="house.floor" type="number" ></el-input>
        </el-form-item>
        <el-form-item label="户型" prop="floor">
          <el-input v-model="house.floor" type="number" ></el-input>
        </el-form-item>
      </el-form>
    </el-card>
  </div>

</template>

<script>
  import ElForm from "../../../node_modules/element-ui/packages/form/src/form.vue";
  import ElFormItem from "../../../node_modules/element-ui/packages/form/src/form-item.vue";
  import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";

  export default {
    components: {
      ElInput,
      ElFormItem,
      ElForm},
    name: "EditHouse",
    data(){
      return{
        houseid:0,
        house:{},
        editRules:{

        }
      }
    },
    async created(){
      this.houseid=this.$route.query.id
      const {data: res}= await this.$http.get("/house/"+this.houseid)
      if(res.code!==200){
        return this.$message.error("获取房源信息失败")
      }
      this.house=res.data
      console.log(this.house)
    }
  }
</script>

<style lang='less' scoped>
 .el-form{
   margin-top: 50px;
   margin-right: 100px;
 }
</style>
