<template>
  <div>
    <!--面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>收藏管理</el-breadcrumb-item>
      <el-breadcrumb-item>收藏列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!--搜索与添加区 其中el-row是列空间系统 一行分为24列, 指定元素宽度添加span属性就行-->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getCollectionList"
          >
            <el-button slot="append"
                       icon="el-icon-search"
                       @click="getCollectionList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!--管理员列表-->
      <el-table :data="collectionlist" border stripe>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="用户id" prop="userId"></el-table-column>
        <el-table-column label="房源id" prop="houseId"></el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <!--删除按钮-->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeAdminById(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.pagenum"
        :page-sizes="[2, 4, 6, 8]"
        :page-size="queryInfo.pagesize"
        layout=" sizes, prev, pager, next, jumper"
        :total="totalpage">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
  export default {
    name: "Collection",
    data(){
      return {
        //        查询所携带的参数
        queryInfo: {
          query: "",
//        该值为在this.$http.get() 请求数据时请求多少页,和设置table有多少页
          pagenum: 1,
//        该值为在this.$http.get() 请求数据时,和设置table每页大小
          pagesize: 6,
        },
        totalpage:0,
        collectionlist:[]
      }
    },
    methods:{
      async getCollectionList(){
        const {data: res}= await this.$http.get("/collection/collection",{
          params: this.queryInfo
        })
        console.log(res)
        if(res.code!==200){
//          说明数据获取失败
          return this.$message.error("获取收藏信息失败")
        }

        this.collectionlist= res.data.collectionlist
        this.queryInfo.pagenum=res.data.pagenum
        this.totalpage=res.data.totalpage
      },
      async removeAdminById(id){
        const confirmResult= await this.$confirm('此操作将永久删除此收藏数据', '是否操作', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)

//       如果用户确认则为confirm字符串
//       如果用户取消删除,则返回值为字符串,cancl

        if(confirmResult!== 'confirm') {
          return this.$message.info('已取消删除')
        }

        const {data: res}= await this.$http.delete('collection/'+id)

        if (res.code !== 200) {
          return this.$message.error('删除收藏数据失败')
        }

        this.$message.success('删除收藏数据成功')
        this.getCollectionList()
      },
      //    监听pageSize改变的事件
      handleSizeChange(newSize) {
        this.queryInfo.pagesize= newSize
        this.getCollectionList()
      },

//    页码值发生改变则触发此事件
      handleCurrentChange(newPage) {
        this.queryInfo.pagenum = newPage
        this.getCollectionList()
      },
    },
    created(){
      this.getCollectionList()
    }
  }
</script>

<style lang='less' scoped>

</style>
